import { Common } from '@stockbit/ui';
import Image from 'global/Image';
import {
  Title,
  Text,
  PriceDesc,
  ContentWrapper,
  PriceWrapper,
  PriceRate,
  PriceLabel,
  ImageWrapper,
} from './styled';
import { Wrapper } from '../styled';

const { Flex } = Common;

function Price() {
  return (
    <Wrapper>
      <ContentWrapper>
        <Flex
          direction="column"
          align="center"
          mx="auto"
          className="big"
          style={{ padding: 0, width: '100%' }}
        >
          <Title small>Biaya Normal</Title>
          <PriceWrapper>
            <Image
              src="/images/table-price-left.svg"
              alt="sprinkle"
              className="left"
            />
            <Flex align="center" direction="column">
              <PriceLabel>Fee Beli</PriceLabel>
              <PriceRate>0,15%</PriceRate>
            </Flex>
            <Flex align="center" direction="column">
              <PriceLabel>Fee Jual</PriceLabel>
              <PriceRate>0,25%</PriceRate>
            </Flex>
            <Image
              src="/images/table-price-right.svg"
              alt="sprinkle"
              className="right"
            />
          </PriceWrapper>
          <PriceDesc size="20px" align="center" padding="15px 60px" as="div">
            <Text size="20px" family="bold" as="b">
              Keterangan:
            </Text>{' '}
            Sudah termasuk biaya broker, biaya levy (BEI, KPEI, KSEI) 0,043%, PPN biaya
            <br />
            broker 10%, dan PPh final untuk transaksi jual 0,1%.
          </PriceDesc>
        </Flex>
      </ContentWrapper>

      <ContentWrapper>
        <ImageWrapper className="small">
          <Image src="/images/buy-sell-image.svg" alt="Bebas biaya broker" />
        </ImageWrapper>
        <Flex direction="column" className="medium">
          <Title>Daftar 100% Online Tanpa Minimum Deposit</Title>
          <Text>
            Akun rekening kamu akan diproses secara digital tanpa perlu mengirim
            dokumen fisik.
          </Text>
        </Flex>
      </ContentWrapper>
    </Wrapper>
  );
}

export default Price;
